// Pricing.js
import React, { useState } from 'react';
import { useAppContext } from '../../contexts/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Pricing.css';

function Pricing({ selectedService = null }) {
  const { t } = useAppContext();
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleCategorySelect = (category) => {
    setSelectedCategory(category === selectedCategory ? '' : category); // Przełączanie wybranej kategorii
  };

  const handleKeyDown = (event, category) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleCategorySelect(category);
    }
  };

  // Mapowanie usług na kategorie cennika
  const servicePricingMap = {
    homeCleaning: ["basicCleaning", "complexCleaning", "additionalServices"],
    upholsteryCleaning: ["cleaningFurniture"],
    carCleaning: ["carCleaningServices"],
    // ...
  };

  let pricingCategories = [];

  if (selectedService) {
    // Pobieramy kategorie cennika dla wybranej usługi
    pricingCategories = servicePricingMap[selectedService] || [];
  } else {
    // Zbieramy wszystkie kategorie cennika ze wszystkich usług
    const allCategories = new Set();
    Object.values(servicePricingMap).forEach((categories) => {
      categories.forEach((category) => allCategories.add(category));
    });
    pricingCategories = Array.from(allCategories);
  }

  // Generowanie tytułu
  const heading = selectedService
    ? `${t.pricingOfServicesFor} ${t.services[selectedService].title}`
    : t.pricingOfServices;

  return (
    <div>
      {/* Warunkowe wyświetlanie sekcji płatności */}
      {!selectedService && (
        <section className="payment-section">
          <h3 className="payment-title">
            <FontAwesomeIcon icon={faMoneyBillWave} className="icon" />
            {t.paymentSectionTitle}
          </h3>
          <p className="payment-description">{t.paymentDescription}</p>
        </section>
      )}

      <div className="pricing-container">
        <h2 className="pricing-title">{heading}</h2>

        {/* Renderujemy kategorie cennika */}
        {pricingCategories.map((categoryKey) => {
          const categoryTitle = t[categoryKey];
          const categoryDetails = t[`${categoryKey}Details`];

          if (!categoryDetails) {
            return null; // Pomijamy, jeśli nie ma szczegółów
          }

          return (
            <div className="pricing-category" key={categoryKey}>
              <button
                className="pricing-header"
                onClick={() => handleCategorySelect(categoryKey)}
                onKeyDown={(event) => handleKeyDown(event, categoryKey)}
                aria-expanded={selectedCategory === categoryKey}
              >
                {categoryTitle} {selectedCategory === categoryKey ? '-' : '+'}
              </button>
              {selectedCategory === categoryKey && (
                <div className="pricing-details expand">
                  {/* Renderowanie szczegółów kategorii zgodnie ze strukturą danych */}
                  {/* Sprawdźmy, czy istnieje pole 'price' i je wyświetlamy */}
                  {categoryDetails.priceTable && Array.isArray(categoryDetails.priceTable) && (
                    <table className="pricing-table">
                      <thead>
                        <tr>
                          <th>{t.tableHeadersArea.area}</th>
                          <th>{t.tableHeadersArea.price}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryDetails.priceTable.map((row, index) => (
                          <tr key={index}>
                            <td>{row.range}</td>
                            <td>{row.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  {/* Jeśli są dostępne 'sections', renderujemy je */}
                  {categoryDetails.sections && (
                    <ul className="sections-list">
                      {Object.keys(categoryDetails.sections).map((sectionKey) => {
                        const section = categoryDetails.sections[sectionKey];
                        // Wariant 1: Jeśli 'details' jest już tablicą:
                        const detailsArray = Array.isArray(section.details)
                          ? section.details
                          : [section.details]; // lub ewentualnie split, jeśli masz string z przecinkami

                        return (
                          <div key={sectionKey} className="pricing-item">
                            <h4>{section.title}</h4>
                            {section.price && <p>{section.price}</p>}

                            {/* Wyświetlanie każdego szczegółu w osobnym <li> */}
                            <ul className="details-list">
                              {detailsArray.map((detail, i) => (
                                <li key={i}>{detail}</li>
                              ))}
                            </ul>
                          </div>
                        );
                      })}
                    </ul>
                  )}

                  {/* Jeśli są podkategorie z 'items', renderujemy je */}
                  {Object.keys(categoryDetails).map((subCategoryKey) => {
                    const subCategory = categoryDetails[subCategoryKey];
                    if (
                      typeof subCategory === 'object' &&
                      subCategory.title &&
                      subCategory.items
                    ) {
                      return (
                        <div key={subCategoryKey} className="pricing-subcategory">
                          <h4>{subCategory.title}</h4>
                          <ul>
                            {subCategory.items.map((item, index) => (
                              <li key={index}>
                                {item.service} - {item.price}
                              </li>
                            ))}
                          </ul>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Pricing;
